<template>
    <section class="page-attention">
        <div class="row-space-tbf">
            <div class="space-left"></div>
            <not-found />
            <div class="space-right"></div>
        </div>
    </section>
</template>

<script>
    import NotFound from '../General/NotFound'
    import NotPaid from '../General/NotPaid'

    export default {
        data(){
            return{
            }
        },
        mounted(){

        },
        components: {
            NotFound,
            NotPaid
        },
        methods: {
        }
    }
</script>